
import themeMixin from '@/mixins/theme'

export default {
  mixins: [themeMixin],
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      selectedTabIndex: 0,
    }
  },
  computed: {
    tabs() {
      return this.slice.tabs
    },
  },
  methods: {
    evenSections(sections) {
      return sections.filter((section, i) => i % 2 === 0)
    },
    oddSections(sections) {
      return sections.filter((section, i) => i % 2 === 1)
    },
  },
}
